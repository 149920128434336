import React from "react";
import { Main } from "./components";
import "./App.css";

const App = () => (
  <div>
    <Main />
  </div>
);

export default App;
